import { RefObject, useLayoutEffect, useRef, useState } from 'react'

type UseWidthReturnedType = [number | undefined, RefObject<HTMLDivElement>]

export function useWidth(): UseWidthReturnedType {
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState<number | undefined>()

  const updateWidth = (): void => {
    setWidth(ref.current?.offsetWidth)
  }

  useLayoutEffect(() => {
    if (!ref.current) return

    updateWidth()

    window.addEventListener('resize', updateWidth)
    return (): void => window.removeEventListener('resize', updateWidth)
  }, [ref])

  return [width, ref]
}
