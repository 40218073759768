import { alpha, Paper as MuiPaper, styled } from '@mui/material'

export const Paper = styled(MuiPaper)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.common.white, 0.7),
  borderRadius: '8px',
  boxShadow: 'unset',
  display: 'flex',
  flex: '1 1 0',
  flexDirection: 'row',
  gap: theme.spacing(2),
  justifyContent: 'center',
  minHeight: 0,
  padding: theme.spacing(1.5, 3),
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    flex: 'unset',
  },
}))

export const DescriptionContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  columnGap: theme.spacing(1.5),
  display: 'flex',
  flexWrap: 'wrap',
}))

export const CountryContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1.5),
}))

export const TextId = styled('span')({
  color: '#797979',
  fontSize: '14px',
  fontWeight: 400,
  letterSpacing: '4%',
  lineHeight: '24px',
})
