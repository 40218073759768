import React, { ReactElement, ReactNode, Suspense } from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom'

import { AppBar, Slide, Stack, useScrollTrigger } from '@mui/material'

import { AnalyticsPageViewTrigger } from '../AnalyticsPageViewTrigger'
import { SceneSpinner } from '../SceneSpinner'
import { Footer } from './components/Footer'
import { GeoSpecificModal } from './components/GeoSpecificModal'
import { getIsShowingGeoSpecificModal } from './components/GeoSpecificModal/utils'
import { Header } from './components/Header'

type CommonLayoutProps = {
  children?: ReactNode
}

export function CommonLayout(props: CommonLayoutProps): ReactElement {
  const { children = <Outlet /> } = props

  const trigger = useScrollTrigger()

  const isShowingGeoSpecificModal = getIsShowingGeoSpecificModal()

  return (
    <>
      <AnalyticsPageViewTrigger />
      {isShowingGeoSpecificModal ? <GeoSpecificModal /> : null}
      <Stack alignItems="center" direction="column" minHeight="100%" position="relative" width="100%">
        <Stack position="relative" width="100%">
          <Slide appear={false} direction="down" in={!trigger}>
            <AppBar position="sticky">
              <Header />
            </AppBar>
          </Slide>
          <Stack
            alignItems="center"
            flex="1 1 0"
            minWidth={0}
            position="relative"
            sx={{ backgroundColor: '#FFF' }}
            width={'100%'}
          >
            <Suspense fallback={<SceneSpinner />}>
              <ScrollRestoration />
              {children}
            </Suspense>
          </Stack>
          <Footer />
        </Stack>
      </Stack>
    </>
  )
}
