import React, { ReactElement, ReactNode } from 'react'

import { Trans } from '@lingui/macro'
import { Box, Typography } from '@mui/material'

import Icon, { type IconType } from '../../../../../../Icon'
import { useWidth } from './hooks/use-width'
import { CountryContainer, DescriptionContainer, Paper, TextId } from './styles'

const MIN_WIDTH = 700

export type RegulationCardProps = {
  title: ReactNode
  imageSrc: string
  flag: IconType
  country: ReactNode
  id: string
  show?: boolean
}

export function RegulationCard(props: RegulationCardProps): ReactElement | null {
  const { country, flag, id, imageSrc, show, title } = props
  const [width, ref] = useWidth()

  if (!show) return null

  return (
    <Paper ref={ref}>
      {width != null && width > MIN_WIDTH ? (
        <Typography variant="body2">
          <Trans>Regulated by the</Trans>{' '}
        </Typography>
      ) : null}
      <Box
        component="img"
        height={{ md: 40, xs: 36 }}
        src={imageSrc}
        sx={{ objectFit: 'contain', objectPosition: 'left' }}
      />
      <DescriptionContainer>
        <Typography fontWeight={700} variant="body2">
          {title}
        </Typography>
        <CountryContainer>
          <Icon fontSize={'16px'} height={24} icon={flag} />
          <Typography variant="body2">{country}</Typography>
        </CountryContainer>
        <TextId>{id}</TextId>
      </DescriptionContainer>
    </Paper>
  )
}
