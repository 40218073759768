import React, { ReactElement } from 'react'

import { Trans } from '@lingui/macro'
import { Button, Stack, Typography } from '@mui/material'

import { useConsentBannerState } from './hook'

export function ConsentBanner(): ReactElement | null {
  const [isConsented, setIsConsented] = useConsentBannerState()

  if (isConsented) return null

  return (
    <Stack
      alignItems="center"
      bottom={0}
      direction="row"
      gap={4}
      justifyContent={{ md: 'center', xs: 'left' }}
      p={1.25}
      position="fixed"
      sx={{ backgroundColor: '#3A3A3A' }}
      width={'100%'}
      zIndex={1}
    >
      <Stack
        alignItems={{ md: 'center', xs: 'left' }}
        direction={{ md: 'row', xs: 'column' }}
        gap={1}
        justifyContent={{ md: 'center', xs: 'left' }}
        sx={{ width: { md: '100%', xs: 'calc(100% - 80px)' } }}
      >
        <Typography color={'#FFF'} textAlign="left" variant="h6">
          <Trans>
            Tauro Markets is a multi-asset investment platform. The value of your investments may go up or down. Your
            capital is at risk.
          </Trans>
        </Typography>
        <Button size="small" sx={{ width: 'fit-content' }} variant="contained" onClick={setIsConsented}>
          OK
        </Button>
      </Stack>
    </Stack>
  )
}
