import { i18n, LocaleData } from '@lingui/core'
import { detect, fromStorage, fromNavigator } from '@lingui/detect-locale'
import { ar, en, ms, th, es, tl, vi, pt, id, ja } from 'make-plural/plurals'
import invariant from 'tiny-invariant'

import { config } from '../../config'
import { defaultLocale, Locale, LOCALE_STORAGE_KEY, locales, Translate } from './constants'

const AllowedLocales = Object.keys(locales)

const plurals: { [K in Locale]: LocaleData } = {
  ar: { plurals: ar },
  en: { plurals: en },
  es: { plurals: es },
  id: { plurals: id },
  ja: { plurals: ja },
  ms: { plurals: ms },
  pt: { plurals: pt },
  th: { plurals: th },
  tl: { plurals: tl },
  vi: { plurals: vi },
}

const isValidLocale = (value: unknown): value is Locale => AllowedLocales.includes(value as Locale)

const localeFallback = (): string => defaultLocale

export function getInitialLocale(): Locale {
  try {
    const locale = detect(fromStorage(LOCALE_STORAGE_KEY), fromNavigator(), localeFallback)
    if (locale != null && isValidLocale(locale)) return locale
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('LocalStorage is not available')
  }

  return defaultLocale
}

export function activateLocale(locale: Locale): void {
  invariant(isValidLocale(locale))

  try {
    localStorage.setItem(LOCALE_STORAGE_KEY, locale)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('LocalStorage is not available')
  }

  const { localeData, messages } = getLocale(locale)

  i18n.load({ [locale]: messages })
  i18n.loadLocaleData({ [locale]: localeData })
  i18n.activate(locale)
}

function getLocale(locale: Locale): Translate {
  const currentLocale = config.locales[locale]

  invariant(currentLocale, `Locale ${locale} is not supported`)
  const localeData = getLocaleData(locale)

  return { ...currentLocale, localeData }
}

function getLocaleData(locale: Locale): LocaleData {
  return plurals[locale] ?? plurals[defaultLocale]
}
